var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container-scroller"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 grid-margin stretch-card"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h3',{staticClass:"card-title text-white rounded p-3 mb-5 text-center",staticStyle:{"background-color":"#0b5d3f","box-shadow":"0 4px 6px rgba(0, 0, 0, 0.1)"}},[_vm._v(" Ajouter une assurance ")]),_c('form',{staticClass:"forms-sample row",on:{"submit":_vm.addAssurance}},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.assuranceName),expression:"assuranceName"}],staticClass:"form-control",class:{
                    'form-control': true,
                    'is-invalid': _vm.formErrors.assuranceName,
                  },attrs:{"type":"text","id":"assuranceName","placeholder":"Nom de l'assurance"},domProps:{"value":(_vm.assuranceName)},on:{"input":[function($event){if($event.target.composing)return;_vm.assuranceName=$event.target.value},function($event){return _vm.clearError('assuranceName')}]}}),(_vm.formErrors.assuranceName)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.formErrors.assuranceName)+" ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.assuranceNumero),expression:"assuranceNumero"}],staticClass:"form-control",class:{
                    'form-control': true,
                    'is-invalid': _vm.formErrors.assuranceNumero,
                  },attrs:{"type":"text","id":"assuranceNumero","placeholder":"Numero de l'assurance"},domProps:{"value":(_vm.assuranceNumero)},on:{"input":[function($event){if($event.target.composing)return;_vm.assuranceNumero=$event.target.value},function($event){return _vm.clearError('assuranceNumero')}]}}),(_vm.formErrors.assuranceNumero)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.formErrors.assuranceNumero)+" ")]):_vm._e(),(_vm.isNumeroTaken)?_c('div',{staticClass:"error-message"},[_vm._v(" Ce numéro est déjà utilisé. Veuillez saisir un autre numéro. ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"assurancePourcentage"}},[_vm._v("Nom compagnie")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.assurancePourcentage),expression:"assurancePourcentage"}],staticClass:"form-control",class:{
                    'form-control': true,
                    'is-invalid': _vm.formErrors.assurancePourcentage,
                  },attrs:{"type":"text","id":"assurancePourcentage","placeholder":"Pourcentage d'assurance","min":"0","max":"100"},domProps:{"value":(_vm.assurancePourcentage)},on:{"input":[function($event){if($event.target.composing)return;_vm.assurancePourcentage=$event.target.value},function($event){return _vm.clearError('assurancePourcentage')}]}}),(_vm.formErrors.assurancePourcentage)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.formErrors.assurancePourcentage)+" ")]):_vm._e()]),_vm._m(3)]),_c('div',{staticClass:"d-flex justify-content-between col-12 flex-row"},[_c('button',{staticClass:"btn btn-secondary btn-medpay-gray",on:{"click":_vm.cancel}},[_vm._v(" Annuler ")]),_c('button',{staticClass:"btn btn-success btn-medpay-green",attrs:{"type":"submit"}},[_vm._v(" Ajouter ")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-header"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{staticClass:"text-dark font-weight-bold",attrs:{"href":"javascript:void(0);"}},[_vm._v("Paramétrage")])]),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v("Assurance")]),_c('li',{staticClass:"breadcrumb-item active text-success font-weight-bold",attrs:{"aria-current":"page"}},[_vm._v(" Ajouter ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"assuranceName"}},[_vm._v("Nom "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"assuranceNumero"}},[_vm._v("Numéro de l'assurance "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"assurancePourcentage"}},[_vm._v("Observations")]),_c('textarea',{staticClass:"form-control",attrs:{"type":"text","id":"assurancePourcentage","rows":"6","placeholder":"Pourcentage d'assurance"}})])
}]

export { render, staticRenderFns }